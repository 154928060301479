<template>
    <div id="AlterarSenha">
        <div class="container">
            <div class="row">
                <div class="col-md-9 col-sm-9 col-11">
                    <div class="row">
                        <div class="col-12 div-top">
                            <div class="div-usuario">
                                <img :src="icone_Usuario" alt="">
                                <div class="infos">
                                    <span class="span_bem_vindo">
                                        Bem vindo (a)
                                    </span>
                                    <span class="nome">
                                        {{cliente.nome}}
                                    </span>
                                    <!-- <span class="localizacao">
                                        Localização não encontrada
                                    </span> -->
                                </div>
                            </div>
                            <div class="wrapper-btns">
                                <a href="javascript:history.back()">
                                    <v-icon>fas fa-times</v-icon>
                                </a>
                            </div>
                        </div>
                        <div class="col-12 titulo-pagina">
                            <span>Alterar senha</span>
                        </div>
                        <div class="col-12 div-editar-perfil">
                            <v-form ref="form_edita_usuario" class="row">
                                <div class="col-md-6 col-12">
                                    <v-text-field
                                        :rules="[v => !!v || 'Campo obrigatório']" 
                                        v-model="cliente.senha"
                                        label="Senha atual"
                                        type="password"
                                        outlined
                                        hide-details
                                    />
                                </div>
                                <span>{{senha_atual}}</span>
                                <div class="col-md-6 col-12">
                                    <v-text-field
                                        :rules="[v => !!v || 'Campo obrigatório']" 
                                        v-model="nova_senha"
                                        label="Nova senha"
                                        type="password"
                                        outlined
                                        hide-details
                                    />
                                </div>
                            </v-form>
                        </div>
                        <div class="col-12 div-bottom">
                            <button class="primary-button" @click="editaUsuario()">
                                Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script>
    import store from '@/store'
    import IconeUsuario from '@/assets/images/REDIRECTUSUARIO.png'
    import IconeSair from '@/assets/images/SAIR.png'
    import IconeHome from '@/assets/images/HOMEICON.png'
    import Loader from '@/components/Loader.vue'

    export default {
        name: 'AlterarSenha',
        components: {
			Loader,
		},
        data: () => ({
            icone_Usuario: IconeUsuario,
            icone_Sair: IconeSair,
            icone_Home: IconeHome,
            loading: false,
            cliente: {
                codigo: '',
                nome: '',
                cpf: '',
                email: '',
                senha: null,
                telefone: '',
                enderecos: [{
                    cep: '',
                    logradouro: '',
                    numero: '',
                    uf_id: '',
                    cidade_id: '',
                    bairro: '',
                    complemento: '',
                    ponto_referencia: '',
                }],
            },
            senha_atual: store.getters.clientes.usuario.senha
        }),
        methods: {
            async init(){
                this.cliente.codigo = store.getters.clientes.usuario.id
                this.cliente.nome = store.getters.clientes.usuario.nome
                this.cliente.cpf = store.getters.clientes.usuario.cpf
                this.cliente.email = store.getters.clientes.usuario.email
                // this.cliente.senha = store.getters.clientes.usuario.senha
                this.cliente.telefone = store.getters.clientes.usuario.telefone
                this.cliente.enderecos[0].cep = store.getters.clientes.usuario.enderecos[0].cep
                this.cliente.enderecos[0].logradouro = store.getters.clientes.usuario.enderecos[0].logradouro
                this.cliente.enderecos[0].numero = store.getters.clientes.usuario.enderecos[0].numero
                this.cliente.enderecos[0].uf_id = store.getters.clientes.usuario.enderecos[0].codigo_estado
                this.cliente.enderecos[0].cidade_id = store.getters.clientes.usuario.enderecos[0].codigo_cidade
                await this.buscaCidades(this.cliente.enderecos[0].uf_id)
                this.cliente.enderecos[0].bairro = store.getters.clientes.usuario.enderecos[0].bairro
                this.cliente.enderecos[0].complemento = store.getters.clientes.usuario.enderecos[0].complemento
                this.cliente.enderecos[0].ponto_referencia = store.getters.clientes.usuario.enderecos[0].ponto_referencia
            },
            async editaUsuario(){
                let temp_usuario = {
                    codigo: this.cliente.codigo,
                    nome: this.cliente.nome,
                    cpf: this.cliente.cpf,
                    email: this.cliente.email,
                    senha: this.nova_senha,
                    telefone: this.cliente.telefone,
                    enderecos: [{
                        cep: this.cliente.enderecos[0].cep,
                        logradouro: this.cliente.enderecos[0].logradouro,
                        numero: this.cliente.enderecos[0].numero,
                        uf_id: this.cliente.enderecos[0].uf_id,
                        cidade_id: this.cliente.enderecos[0].cidade_id,
                        bairro: this.cliente.enderecos[0].bairro,
                        complemento: this.cliente.enderecos[0].complemento,
                        ponto_referencia: this.cliente.enderecos[0].ponto_referencia,
                    }],
                }
				if (this.$refs.form_edita_usuario.validate()) {
                    if (this.cliente.senha == this.nova_senha) {
                        this.$swal({
                            title: 'Atenção',
                            icon:'warning',
                            text: 'Escolha uma senha diferente da atual!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                        return;
                    }
                    this.loading = await true
                    var resp = await store.dispatch('salvaCliente', temp_usuario)
                    if (resp.sucesso) {
                        this.$swal({
                            title: 'Atenção',
                            icon:'success',
                            text: 'Usuário editado com sucesso!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                        this.$router.push('/' + this.$route.params.url_amigavel + '/home/')
                        document.location.reload(true);
                    }else{
                        this.$swal({
                            title: 'Atenção',
                            icon:'error',
                            text: 'Não foi possível concluir as alterações!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                    }
				}
			},
        },
        async mounted(){
            await this.init()
        }
    }
</script>

<style lang="scss">
    #AlterarSenha{
        position: relative;
        .div-top{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .div-usuario{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .infos{
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    padding-left: 23px;
                    span{
                        font-family: 'Poppins';
                        font-style: normal;
                        font-weight: 300;
                        font-size: 16px;
                        line-height: 24px;
                        color: #000000;
                        &.span_bem_vindo{
                            font-weight: 700;
                        }
                        &.localizacao{
                            font-size: 18;
                            font-family: 'JosefinSans';
                        }
                    }
                }
                .icones{
                    img{
                        padding-left: 25px;
                    }
                }
            }
            .wrapper-btns{
                display: flex;
                align-items: center;
                button{
                    display: flex;
                    padding-right: 20px;
                    img{
                        width: 32px;
                    }
                }
                svg{
                    font-size: 25px;
                    margin-left: 10px;
                    background-color: transparent;
                    width: 40px;
                    height: 40px;
                    padding: 8px;
                    border-radius: 30px;
                    transition: all ease .3s;
                    path{
                        fill: $primarycolor;
                    }
                    &:hover{
                        background-color: #f3f3f3;
                    }
                    &:active{
                        background-color: #dadada;
                    }
                }
            }
            @media (max-width: 350px){
                flex-direction: column;
                text-align: center;
                align-items: center;
                justify-content: center;
                .wrapper-btns{
                    margin-top: 15px;
                }
            }
        }
        .titulo-pagina{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            span{
                font-size: 25px;
                font-weight: 600;
                font-family: 'Poppins';
                text-align: center;
                width: 100%;
            }
        }
        .div-editar-perfil{
            form{
                padding: 18px 0;
                h2{					
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 36px;
                    color: $primarycolor;
                }
                .v-input__slot{
                    border-radius: 100px!important;
                    padding-left: 15px;
                }
                @media (max-width: 1400px){
                    padding: 0 15px;
                }
            }
        }
        .div-bottom{
            padding: 0 27px;
            .primary-button{
                min-height: 60px;
                width: max-content;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;
                border-width: 2px;
            }
        }
        &:before{
            content: '';
            position: fixed;
            top: 0;
            right: 0;
            background-position: center;
            width: 25%;
            height: 100vh;
            z-index: 1;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url('~@/assets/images/sideEditUsuario.png');
            @media (max-width: 599px){
                display: none;
            }
        }
    }
</style>